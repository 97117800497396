
export const blogData = [
    {
        id: 1,
        category: ["Web design", "Concept"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/SCTE.jpeg",
        title: "SCTE",
        content: "SCTE Chapters provide local networking, professional development and certification opportunities to current and prospective members across 60 locations across the globe.",
        author: '',
        likes: '',
        comments: 0,
        date: "",
        link: "https://www.scte.org/"
    },
    {
        id: 2,
        category: ["CONCEPT", "Web design"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/Oracle.jpeg",
        title: "Oracle",
        content: "Explore cloud training resources with Oracle Cloud Infrastructure training videos, self-paced learning labs, and certifications.",
        author: '',
        likes: '',
        comments: 0,
        date: "",
        link: "https://www.oracle.com/"
    },
    {
        id: 3,
        category: ["NATURE"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/IEEE.jpeg",
        title: "IEEE",
        content: "IEEE is the world’s largest technical professional organization dedicated to advancing technology for the benefit of humanity.",
        author: '',
        likes: '',
        comments: 0,
        date: "",
        link: "https://www.ieee.org/"
    },
]


export const BlogCategoryData = [
    {
        id: 118,
        category: "LIFESTYLE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 119,
        category: "SUMMER",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 120,
        category: "COFFEE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 121,
        category: "FASHION",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 122,
        category: "DRINKS",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    }
]

export const authorData = [
    {
        id: 1,
        img: "https://via.placeholder.com/125x125",
        name: "Torrie asai",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 2,
        img: "https://via.placeholder.com/125x125",
        name: "Walton smith",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 3,
        img: "https://via.placeholder.com/125x125",
        name: "Bill gardner",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 4,
        img: "https://via.placeholder.com/125x125",
        name: "Vico magistre",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 5,
        img: "https://via.placeholder.com/125x125",
        name: "Coco chanel",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 6,
        img: "https://via.placeholder.com/125x125",
        name: "Mark lamb",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 7,
        img: "https://via.placeholder.com/125x125",
        name: "Lindsey bucki",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 8,
        img: "https://via.placeholder.com/125x125",
        name: "Maya angelou",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 9,
        img: "https://via.placeholder.com/125x125",
        name: "Jeremy dupont",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
]

export const FilterData = [
    {
        title: "All",
        key: "*"
    },
    {
        title: "FASHION",
        key: "fashion "
    },
    {
        title: "TRAVEL",
        key: "travel "
    },
    {
        title: "LIFESTYLE",
        key: "lifestyle "
    },
    {
        title: "BUSINESS",
        key: "business"
    }
]